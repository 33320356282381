import React from "react";
import NextApp from "next/app";
import { useRouter } from "next/router";
import { Helmet } from "react-helmet";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import { SiteContext, useSiteContext } from "../components/hooks/useSite";
import { helmetSettingsFromMetadata } from "../lib/site";
import "../styles/globals.css";

import { getSiteMetadata } from "../lib/site";
config.autoAddCss = false;

function MyApp(props) {
  const { Component, pageProps, metadata } = props;

  const getLayout = Component.getLayout || ((page) => page);

  const { myCustomData, ...rest } = pageProps;

  const site = useSiteContext({
    metadata,
  });
  const router = useRouter();
  const { asPath } = router;

  const { homepage = "" } = site;

  if (!metadata.og) {
    metadata.og = {};
  }

  metadata.og.url = `${homepage}${asPath}`;

  if (typeof window === "undefined") React.useLayoutEffect = () => {};

  const helmetSettings = {
    defaultTitle: metadata?.title || "Title",
    titleTemplate: process.env.WORDPRESS_PLUGIN_SEO
      ? "%s"
      : `%s - ${metadata?.title || "Title"}`,
    ...helmetSettingsFromMetadata(metadata, {
      setTitle: false,
      link: [
        {
          rel: "alternate",
          type: "application/rss+xml",
          href: "/feed.xml",
        },

        // Favicon sizes and manifest generated via https://favicon.io/

        {
          rel: "apple-touch-icon",
          sizes: "180x180",
          href: "/apple-touch-icon.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/favicon-16x16.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/favicon-32x32.png",
        },
        {
          rel: "manifest",
          href: "/site.webmanifest",
        },
      ],
    }),
  };

  return getLayout(
    <SiteContext.Provider value={site}>
      <Helmet {...helmetSettings} />
      <Component {...rest} />
    </SiteContext.Provider>,
    myCustomData
  );
}

MyApp.getInitialProps = async function (appContext) {
  const appProps = await NextApp.getInitialProps(appContext);

  return {
    ...appProps,
    metadata: await getSiteMetadata(),
  };
};

export default MyApp;
